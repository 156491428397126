import React from 'react';
import PropTypes from 'prop-types';


const propTypes = {};

const defaultProps = {};


const NavBar = (prop) => {
    return <div>
        <nav className="navbar navbar-dark navbar-expand-lg  bg-dark ">
        {/* navbar  navbar-expand-lg bg-body-tertiary */}
  <div className="container-fluid">
    <a className="navbar-brand" href="/">{prop.title}</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="/navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a className="nav-link" aria-current="page" href="/">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/">Link</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
    </div>;
}

NavBar.propTypes = {title: PropTypes.string}
NavBar.defaultProps = {title: "Todo List"}

export default NavBar;