import './App.css';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import Todos from './components/Todos';
import { useState } from 'react';
import AddTodo from './components/AddTodo';
import {useEffect} from 'react';

function App() {
  let initTodo;
  if(localStorage.getItem("todos")===null){
    initTodo = []
  }
  else{
    initTodo = JSON.parse(localStorage.getItem("todos"))
  }
  
  const onDelete =(todo)=>{
    setTodo(todos.filter((e)=>{
      return e!==todo
    }))
  }

  const addTodo =(title, description)=>{
    console.log("I am adding this todo", title, description)
    let sno;
    if(todos.length===0){
      sno = 0
    }
    else{
       sno = todos[todos.length-1].sno + 1
    }
    const myTodo ={
      sno: sno,
      title: title,
      description: description
    }
    setTodo([...todos,myTodo])
    console.log(myTodo)

  }

  const [todos, setTodo] = useState(initTodo)
  useEffect(() => {
  localStorage.setItem("todos", JSON.stringify(todos))
  }, [todos])


  return ( 
    <>
    <NavBar title={"Todo List"}/>
    <AddTodo addTodo={addTodo}/>
    <Todos todos={todos} onDelete={onDelete}/>
    <Footer/>
    </>
  );
}

export default App;
