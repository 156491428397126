import React, {useState} from "react";

const propTypes = {};

const defaultProps = {};

const AddTodo = ({addTodo}) => {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")

  const submit =(e)=>{
    e.preventDefault();
    if(!title || !description){
      alert("Title or description cannot be empty")
    }
    else{
    addTodo(title, description)
    setTitle("")
    setDescription("")
    }
  }

  return (
    <div className="container my-4">
      <form onSubmit={submit}>
        <div className="mb-3" >
          <label htmlFor="title" className="form-label">
            Todo Title
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            aria-describedby="emailHelp"
            value={title}
            onChange={(e)=>{setTitle(e.target.value)}}
          />
        </div>
        <div className="mb-3" >
          <label htmlFor="desc" className="form-label">
            Description
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputPassword1"
            value={description}
            onChange={(e)=>{setDescription(e.target.value)}}
          />
        </div>
        <button type="submit" className="btn btn-sm btn-success">
          Add Todo
        </button>
      </form>
    </div>
  );
};

AddTodo.propTypes = propTypes;
AddTodo.defaultProps = defaultProps;

export default AddTodo;
