import React from 'react';
import PropTypes from 'prop-types';


const propTypes = {};

const defaultProps = {};


const Footer = () => {
    return (

        <footer className="bg-dark text-light py-3">
            <div className="text-center">
                <p>Copyright &copy; Todos List</p>
            </div>
        </footer>
    )
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;